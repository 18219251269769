import { getUrlQueryParams } from '@/utils/url';

export const usePagination = ({ location, itemsPerPage, items }) => {
  const pageInUrl = getUrlQueryParams(location)['page'];
  const page = pageInUrl ? +pageInUrl : 1;
  const currentPageIndex = page - 1;
  const startIndex = currentPageIndex * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pagedItems = items.slice(startIndex, endIndex);
  return [pagedItems, page];
};

import { navigate } from 'gatsby';
import qs from 'qs';
import React from 'react';

import EmptyList from '@/components/common/Empty/List';
import Loading from '@/components/common/Loading';
import Pagination from '@/components/common/Pagination';
import { usePagination } from '@/hooks/usePagination';
import { getProductImages } from '@/utils/traversing';
import { getUrlQueryParams } from '@/utils/url';

import Card from './Card';
import * as style from './list.module.scss';

const List = ({
  products,
  productsPerPage = 20,
  images,
  location,
  className,
  isFilterable = true,
}) => {
  const [pagedProducts, currentPage] = usePagination({
    location,
    itemsPerPage: productsPerPage,
    items: products,
  });

  const handlePageChange = (page) => {
    const existingQuery = getUrlQueryParams(location);
    const query = {
      ...existingQuery,
      page,
    };
    navigate(`${location.pathname}?${qs.stringify(query, { encode: false })}`);
  };

  return (
    <div className={className}>
      {products.length ? (
        <>
          <div className={style.grid} role="list">
            {pagedProducts.map((product) => {
              const productImages = getProductImages(product.uuid, images);
              return (
                <Card
                  role="listitem"
                  key={product.uuid}
                  product={product}
                  images={productImages}
                  location={location}
                />
              );
            })}
          </div>
          <Pagination
            className={style.pagination}
            page={currentPage}
            itemsPerPage={productsPerPage}
            itemsCount={products.length}
            onPageChange={(page) => handlePageChange(page)}
          />
        </>
      ) : isFilterable ? (
        <EmptyList className={style.empty}>
          <p>No hay productos que coincidan con tu búsqueda</p>
        </EmptyList>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default List;

import { graphql, useStaticQuery } from 'gatsby';

import { mergeProductsAndPrices } from '@/utils/traversing';

export const useOfferProductsQuery = () => {
  const query = useStaticQuery(graphql`
    query offerProducts {
      allGoogleSpreadsheetProducts(
        filter: { isOffer: { eq: "TRUE" }, isPublished: { eq: "TRUE" } }
      ) {
        edges {
          node {
            uuid
            title
            shortDescription
            description
            brand
            isNew
            isFeatured
            isOffer
          }
        }
      }
    }
  `);

  return mergeProductsAndPrices(query);
};

// extracted by mini-css-extract-plugin
export var description = "featuredproductcard-module--description--978c9";
export var details = "featuredproductcard-module--details--c1cfe";
export var image = "featuredproductcard-module--image--1198b";
export var img = "featuredproductcard-module--img--69d66";
export var info = "featuredproductcard-module--info--9f776";
export var main = "featuredproductcard-module--main--9d901";
export var mainAction = "featuredproductcard-module--mainAction--3ddbf";
export var placeholder = "featuredproductcard-module--placeholder--03dba";
export var price = "featuredproductcard-module--price--dc74c";
export var shortDescription = "featuredproductcard-module--shortDescription--12aab";
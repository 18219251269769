import cx from 'classnames';
import React from 'react';

import * as style from './emptylist.module.scss';

const EmptyList = ({ className, children }) => (
  <div className={cx(style.main, className)}>{children}</div>
);

export default EmptyList;

import cx from 'classnames';
import React, { useState } from 'react';

import FeaturedSvg from '@/assets/icons/Featured.svg';
import NewSvg from '@/assets/icons/New.svg';
import OfferSvg from '@/assets/icons/Offer.svg';

import * as style from './badge.module.scss';

const refs = {
  isNew: {
    icon: <NewSvg />,
    tooltip: 'Novedad',
  },
  isFeatured: {
    icon: <FeaturedSvg />,
    tooltip: 'Recomendado',
  },
  isOffer: {
    icon: <OfferSvg />,
    tooltip: 'Oferta',
  },
};

const Badge = ({ badgeType, className }) => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  return (
    <div
      className={cx(style.main, className, style[badgeType])}
      onMouseEnter={() => setTooltipIsVisible(true)}
      onMouseLeave={() => setTooltipIsVisible(false)}
      role="button"
      tabIndex={0}
    >
      <span className={cx(style.tooltip, tooltipIsVisible && style.visible)} role="tooltip">
        {refs[badgeType].tooltip}
      </span>
      <div className={style.icon}>{refs[badgeType].icon}</div>
    </div>
  );
};

export default Badge;

import cx from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { get } from 'lodash';
import React from 'react';

import Button from '@/components/common/Button';
import MissingImage from '@/components/common/Empty/MissingImage';
import Markdown from '@/components/common/Markdown';
import Price from '@/components/common/Price';
import { ROUTE_NAMES, stringToSlug } from '@/utils/url';

import * as style from './featuredproductcard.module.scss';

const FeaturedProductCard = ({ product }) => {
  const { images, priceArs, title, shortDescription, description } = product;
  const truncatedDescription =
    description && `${description.slice(0, 500)}${description.length > 500 ? '…' : ''}`;
  const productUrl = `/${ROUTE_NAMES.PRODUCTS}/${stringToSlug(title)}`;
  return (
    <article className={style.main}>
      <Link className={style.image} to={productUrl}>
        {images.length ? (
          <GatsbyImage
            alt={title}
            className={style.img}
            objectFit="contain"
            objectPosition="center center"
            image={get(images, '[0].childImageSharp.gatsbyImageData')}
          />
        ) : (
          <MissingImage className={cx(style.img, style.placeholder)} />
        )}
      </Link>
      <section className={style.info}>
        <div className={style.details}>
          <Price priceArs={priceArs} className={style.price} />
          <h1>{title}</h1>
          <p className={style.shortDescription}>{shortDescription}</p>
          {description && <Markdown className={style.description} source={truncatedDescription} />}
        </div>
        <Link to={productUrl}>
          <Button className={style.mainAction} isSecondary>
            Ver Detalles
          </Button>
        </Link>
      </section>
    </article>
  );
};

export default FeaturedProductCard;

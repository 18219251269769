import React, { useEffect, useState } from 'react';

import Carousel from '@/components/common/Carousel';
import { useProductImagesQuery } from '@/query/product_images';
import { useFeaturedProductsQuery } from '@/query/products_featured';
import { randomizeArray } from '@/utils/array';
import { getLocalOrRemoteImages, getProductImages } from '@/utils/traversing';

import FeaturedProductCard from './FeaturedProductCard';

const FeaturedProductsCarousel = ({ className }) => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  const featuredProductsQuery = useFeaturedProductsQuery();

  useEffect(() => {
    setFeaturedProducts(
      randomizeArray([
        ...featuredProductsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node),
      ]).slice(-10),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const productImagesQuery = useProductImagesQuery();
  const images = getLocalOrRemoteImages(productImagesQuery);

  const productWithImages = (product) => ({
    ...product,
    images: getProductImages(product.uuid, images),
  });
  return (
    <Carousel className={className}>
      {featuredProducts.map((product) => (
        <FeaturedProductCard product={productWithImages(product)} key={product.uuid} />
      ))}
    </Carousel>
  );
};

export default FeaturedProductsCarousel;

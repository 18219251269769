import { Link } from 'gatsby';
import React from 'react';

import Price from '@/components/common/Price';
import ProductBadge from '@/components/section/Product/Badge';
import { ROUTE_NAMES, stringToSlug } from '@/utils/url';

import CardGallery from './CardGallery';
import * as style from './card.module.scss';

const Card = ({ product, images, location }) => {
  const { title, shortDescription, isNew, isFeatured, isOffer, priceArs } = product;

  const query = (location && location.search) || '';
  const productUrl = `/${ROUTE_NAMES.PRODUCTS}/${stringToSlug(title)}${query}`;
  const showBadges = isNew === 'TRUE' || isFeatured === 'TRUE' || isOffer === 'TRUE';

  return (
    <article className={style.main} data-uuid={product.uuid}>
      <Link to={productUrl}>
        <CardGallery title={title} images={images} />
      </Link>
      <section className={style.content}>
        {showBadges ? (
          <div className={style.badges}>
            {isNew === 'TRUE' && <ProductBadge className={style.badge} badgeType="isNew" />}
            {isFeatured === 'TRUE' && (
              <ProductBadge className={style.badge} badgeType="isFeatured" />
            )}
            {isOffer === 'TRUE' && <ProductBadge className={style.badge} badgeType="isOffer" />}
          </div>
        ) : null}
        <Price className={style.price} priceArs={priceArs} />
        <h1 className={style.title}>
          <Link to={productUrl}>{title}</Link>
        </h1>
        <p className={style.description}>{shortDescription}</p>
      </section>
    </article>
  );
};

export default Card;

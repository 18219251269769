import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import '@/assets/styles/global.scss';
import Button from '@/components/common/Button';
import FeaturedBrands from '@/components/section/Brand/FeaturedBrands';
import FeaturedProductsCarousel from '@/components/section/Product/FeaturedProductsCarousel';
import ProductsList from '@/components/section/Product/List';
import Seo from '@/components/seo';
import { useProductImagesQuery } from '@/query/product_images';
import { useNewProductsQuery } from '@/query/products_new';
import { useOfferProductsQuery } from '@/query/products_offer';
import { randomizeArray } from '@/utils/array';
import { getLocalOrRemoteImages } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './homepage.module.scss';

const IndexPage = ({ location }) => {
  const [newProducts, setNewProducts] = useState([]);
  const [offerProducts, setOfferProducts] = useState([]);

  const productImagesQuery = useProductImagesQuery();
  const newProductsQuery = useNewProductsQuery();
  const offerProductsQuery = useOfferProductsQuery();

  useEffect(() => {
    setNewProducts(
      randomizeArray([
        ...newProductsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node),
      ]).slice(-8),
    );
    setOfferProducts(
      randomizeArray([
        ...offerProductsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node),
      ]).slice(-8),
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const productImages = getLocalOrRemoteImages(productImagesQuery);

  return (
    <>
      <Seo title="Home" />
      <section className={style.featuredProductsSection}>
        <FeaturedProductsCarousel className={style.featuredProducts} />
      </section>
      <section className={style.newProductsSection}>
        <div className={style.sectionHeader}>
          <h2>Novedades</h2>
          <Link to={`/${ROUTE_NAMES.PRODUCTS}?newArrivals=1`}>
            <Button>
              Ver todas<span className={style.extraText}> las novedades</span>
            </Button>
          </Link>
        </div>
        <ProductsList
          location={location}
          className={style.newProducts}
          products={newProducts}
          images={productImages}
          isFilterable={true}
        />
      </section>
      <FeaturedBrands />
      <section className={style.newProductsSection}>
        <div className={style.sectionHeader}>
          <h2>Ofertas</h2>
          <Link to={`/${ROUTE_NAMES.PRODUCTS}?offers=1`}>
            <Button>
              Ver todas
              <span className={style.extraText}> las ofertas</span>
            </Button>
          </Link>
        </div>
        <ProductsList
          location={location}
          className={style.offerProducts}
          products={offerProducts}
          images={productImages}
          isFilterable={false}
        />
      </section>
    </>
  );
};

export default IndexPage;

// extracted by mini-css-extract-plugin
export var badge = "card-module--badge--46c69";
export var badges = "card-module--badges--8b064";
export var closeButton = "card-module--closeButton--8fb3e";
export var content = "card-module--content--f2f3f";
export var description = "card-module--description--6131a";
export var link = "card-module--link--e810b";
export var main = "card-module--main--bc8c8";
export var price = "card-module--price--4673a";
export var productRow = "card-module--productRow--c9d9f";
export var title = "card-module--title--7b87f";
import cx from 'classnames';
import React from 'react';

import ChevronLeft from '@/assets/icons/ChevronLeft.svg';
import ChevronRight from '@/assets/icons/ChevronRight.svg';
import { accessibleOnClick } from '@/utils/react';

import * as style from './pagination.module.scss';

const Pagination = ({
  className,
  page = 1,
  itemsCount,
  onPageChange,
  itemsPerPage = 20,
  windowSize = 5,
}) => {
  const totalPages = Math.ceil(itemsCount / itemsPerPage);
  const pagesArray = Array.from({ length: totalPages }, (e, i) => i + 1);
  const halfWindowSize = Math.floor(windowSize / 2);
  const maxStartIndex = totalPages - windowSize - 1;
  const minEndIndex = windowSize;
  const startIndex = Math.max(Math.min(maxStartIndex, page - 1 - halfWindowSize), 0);
  const endIndex = Math.max(minEndIndex, Math.min(page + halfWindowSize, totalPages));
  const pagesWindow = pagesArray.slice(startIndex, endIndex);
  const prevDisabled = page <= 1;
  const nextDisabled = page >= totalPages;

  return (
    <nav className={cx(style.main, className)} role="navigation">
      <div
        tabIndex={0}
        className={cx(style.button, prevDisabled && style.disabled)}
        {...accessibleOnClick(() => onPageChange(page - 1))}
        role="button"
      >
        <ChevronLeft />
      </div>
      {pagesWindow.map((e) => (
        <div
          tabIndex={0}
          role="button"
          key={e}
          className={cx(style.page, page === e ? style.active : '')}
          {...accessibleOnClick(() => onPageChange(e))}
        >
          {e}
        </div>
      ))}
      <div
        tabIndex={0}
        role="button"
        className={cx(style.button, nextDisabled && style.disabled)}
        {...accessibleOnClick(() => onPageChange(page + 1))}
      >
        <ChevronRight />
      </div>
    </nav>
  );
};

export default Pagination;

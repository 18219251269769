import { Link } from 'gatsby';
import React from 'react';

import Fender from '@/assets/images/marcas/fender/fender.svg';
import Jbl from '@/assets/images/marcas/jbl/jbl.svg';
import Remo from '@/assets/images/marcas/remo/remo.svg';
import Shure from '@/assets/images/marcas/shure/shure.svg';
import Tascam from '@/assets/images/marcas/tascam/tascam.svg';
import Yamaha from '@/assets/images/marcas/yamaha/yamaha.svg';
import Button from '@/components/common/Button';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './featuredbrands.module.scss';

const FeaturedBrands = () => {
  return (
    <section className={style.main}>
      <div className={style.container}>
        <h1>Nuestras Marcas</h1>
        <p className={style.lead}>
          Trabajamos marcas de primera línea a través de importadores directos, para asegurar la
          calidad y legitimidad de todos nuestros productos.
        </p>
        <ul className={style.brandsList}>
          {[<Fender />, <Yamaha />, <Shure />, <Remo />, <Tascam />, <Jbl />].map((e, i) => (
            <li className={style.brandItem} key={i}>
              {e}
            </li>
          ))}
        </ul>
        <div className={style.cta}>
          <Link to={`/${ROUTE_NAMES.BRANDS}`}>
            <Button className={style.more}>Ver todas las marcas</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedBrands;

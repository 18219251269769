import { graphql, useStaticQuery } from 'gatsby';

export const useProductImagesQuery = () => {
  return useStaticQuery(graphql`
    query productImages {
      allFile(filter: { relativeDirectory: { regex: "/productos/" } }) {
        edges {
          node {
            relativePath
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      allS3Object(filter: { Key: { regex: "/productos//" } }) {
        edges {
          node {
            Key
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);
};

import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import MissingImage from '@/components/common/Empty/MissingImage';

import * as style from './cardgallery.module.scss';

const CardGallery = ({ images, className, title }) => {
  const hasImages = images && !!images.length;
  const firstImage = hasImages
    ? images.sort((a, b) => (b.Key > a.Key || b.relativePath > a.relativePath ? -1 : 1))[0]
    : null;

  return (
    <div className={cx(style.main, className)}>
      {hasImages ? (
        <GatsbyImage
          alt={title}
          className={style.image}
          objectFit="contain"
          objectPosition="center center"
          image={firstImage.childImageSharp.gatsbyImageData}
        />
      ) : (
        <MissingImage className={style.image} />
      )}
    </div>
  );
};

export default CardGallery;

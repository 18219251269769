import { graphql, useStaticQuery } from 'gatsby';

import { mergeProductsAndPrices } from '@/utils/traversing';

export const useNewProductsQuery = () => {
  const query = useStaticQuery(graphql`
    query newProducts {
      allGoogleSpreadsheetProducts(filter: { isNew: { eq: "TRUE" }, isPublished: { eq: "TRUE" } }) {
        edges {
          node {
            uuid
            title
            shortDescription
            description
            brand
            isNew
            isFeatured
            isOffer
          }
        }
      }
    }
  `);

  return mergeProductsAndPrices(query);
};
